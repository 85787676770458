<template>
  <div v-if="unk == true" class="bracket__item">
    ??
  </div>
  <div v-else-if="!info" class="bracket__item">
    <div class="bracket__content">
      <span class="bracket__bg"><v-icon>mdi-account</v-icon></span>
      <v-menu
        v-model="menu"
        bottom
        origin="center center"
        transition="scale-transition"
        :close-on-content-click="true"
      >
        <template #activator="{ on, attrs }">
          <span
            v-bind="attrs"
            @click="switchOpen"
            v-on="on"
          >
            Open Slot {{ info }}
          </span>
        </template>

        <v-list v-if="!connected">
          Please connect first..
        </v-list>
        <v-list v-else>
          <v-list-item>
            <v-list-item-title>Join w/ {{game}}<v-btn text outlined small class="px-0 mx-1" @click="joinSlot">{{ leagueProfile }}</v-btn></v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-text-field
                v-model="nickname"
                append-outer-icon="mdi-send"
                :rules="[v => v.length <= 25 || 'Max 25 characters']"
                counter="25"
                label="Join w/ alias"
                @click.stop.prevent
                @click:append-outer="sendMessage"
              />
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-col class="d-flex justify-end pa-0">
        <span style="font-size:12px">0 <v-icon x-small>mdi-account</v-icon></span>
      </v-col>
      <v-col class="d-flex justify-end pa-0">
        <p class="bracket__score d-flex justify-center align-center">?</p>
      </v-col>
    </div>
  </div>
  <div v-else class="bracket__item">
    <div class="bracket__content">
      <span class="bracket__bg d-flex justify-center"><v-icon>mdi-account</v-icon></span>
      <span class="">{{info.alias}}</span>
      <v-col class="d-flex justify-end pa-0">
        <span style="font-size:12px">15 <v-icon x-small>mdi-account</v-icon></span>
      </v-col>
      <v-col class="d-flex justify-end pa-0">
        <p class="bracket__score d-flex justify-center align-center">10</p>
      </v-col>
    </div>
  </div>
</template>
<script>
  import {
    mapActions,
    mapState,
  } from 'vuex'
  export default {
    name: 'DefaultCard',
    props: {
      id: Number,
      nslot: Number,
      info: Object,
      game: String,
      unk: Boolean,
    },
    data () {
      return {
        nickname: '',
        menu: false,
        openSlot: false,
      }
    },
    computed: {
      ...mapState('wallet', ['connected', 'profile']),
      leagueProfile () {
        if (this.game === 'axie') {
          return this.profile.profiles.axie.name
        } else if (this.game === 'lol') {
          return this.profile.profiles.lol.name
        }
        return 'unk?'
        // return 'xxt'
      },
    },
    methods: {
      ...mapActions('wallet', ['sendMsg']),
      joinSlot () {
        console.log(this.nslot, this.leagueProfile)
        this.sendMsg(JSON.stringify({ msg: 'join_tournament', id: this.id, slot: this.nslot, alias: this.leagueProfile })) // tell me mode, alias and price, also options..
      },
      switchOpen () {
        this.openSlot = !this.openSlot
      },
      sendMessage () {
        this.sendMsg(JSON.stringify({ msg: 'join_tournament', id: this.id, slot: this.nslot, alias: this.nickname })) // tell me mode, alias and price, also options..
        this.nickname = ''
        this.menu = false
      },
    },
  }
</script>

<style>
  .bracket__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bracket__item {
    width: 232px;
    height: 43px;
    border-radius: 10px;
    background-color: #1b1d21;
    margin: 4px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    position: relative;
  }

  .bracket__item:nth-child(even) {
    margin-bottom: 16px !important;
  }

  .bracket__bg {
    width: 34px;
    height: 33px;
    border-radius: 5px;
    background-color: #2a3441;
    margin: 6px 8px 6px 8px !important;
    display: flex;
    justify-content: center;
  }

  .bracket__score {
    width: 34px;
    height: 33px;
    border-radius: 5px;
    background-color: #e36529;
    margin: 6px 7px 6px 7px !important;
  }

</style>
